import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink";

import MobileNav from "./MobileNav";

import "./../sass/header.scss";

const toSlug = object_slug => {
  return `/${object_slug}`;
};

const TankLogoSideways = ({ isHidden }) => {
  return (
    <div className="tank-logo-sideways__wrapper">
      <div
        className={`tank-logo-sideways__container ${isHidden ? "hidden" : ""}`}
      >
        <a href="/" className="tank-logo-sideways__text">
          Tank
        </a>
      </div>
    </div>
  );
};

const HeaderLink = ({ item }) => {
  return (
    <li className="header__li">
      <TransitionLink to={toSlug(item.object_slug)} className="link">
        {item.title}
      </TransitionLink>
    </li>
  );
};

const HeaderLinkWithChildren = ({ item }) => {
  return (
    <li className="header__li nested-navbar animated-underline">
      <a href={toSlug(item.object_slug)} className="link">
        {item.title}
      </a>
      <ul className="header__child-ul">
        {item?.wordpress_children?.map((child, i) => {
          return (
            <li className="">
              <a href={toSlug(child.object_slug)} className="child-link">
                {child.title}
              </a>
            </li>
          );
        })}
      </ul>
    </li>
  );
};
const VerticalBar = () => {
  return <div className="header__vertical-bar"></div>;
};

const Header = ({ isHidden, className }) => {
  const {
    menu: {
      edges: [{ node: menu }]
    }
  } = useStaticQuery(staticHeaderQuery);
  return (
    <>
      {/* Mobile Nav */}
      <MobileNav menu={menu} className={className} />
      {/* END Mobile Nav */}

      <div className={`desktop-only header-section ${isHidden ? 'hidden': ''}`}>
        <TankLogoSideways isHidden={isHidden} />
        <div className="header-container">
          <nav className="header">
            <ul className="header__ul">
              {menu.items.map((item, i) => {
                if (item.wordpress_children) {
                  return (
                    <HeaderLinkWithChildren item={item} key={item.title} />
                  );
                }
                return <HeaderLink item={item} key={item.title} />;
              })}

              <VerticalBar />
              <HeaderLink item={{ title: "Contact", object_slug: "contact" }} />
            </ul>
          </nav>
        </div>
      </div>

      <div className="header-section-height"></div>
    </>
  );
};

export default Header;

const staticHeaderQuery = graphql`
  query Header2Query {
    site {
      siteMetadata {
        title
      }
    }
    menu: allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main" } }) {
      totalCount
      edges {
        node {
          items {
            title
            url
            description
            object_slug
            wordpress_children {
              title
              url
              object_slug
            }
          }
          name
        }
      }
    }
  }
`;
