import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'


function SEO({ yoast }) {
  
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        
        //const metaImage = image && image.src ? `${data.site.siteMetadata.siteUrl}${image.src}` : null
        const metaImage = null;
        const decodeHTML = function(str) {
          return str.replace(/&#(\d+);/g, function(match, dec) {
            return String.fromCharCode(dec);
          });
        };
        const pageTitle = decodeHTML(yoast.title);
        return (
          <Helmet
            
            title={pageTitle}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={
              [
                {
                  name: `description`,
                  content: yoast.metadesc,
                },
                {
                  property: `og:title`,
                  content: yoast.opengraph_title,
                },
                {
                  property: `og:url`,
                  content: yoast.canonical,
                },
                {
                  property: `og:description`,
                  content: yoast.opengraph_description,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: `twitter:creator`,
                  content: `@tank_design`,
                }, 
                {
                  name: `twitter:title`,
                  content: yoast.twitter_title,
                },
                {
                  name: `twitter:description`,
                  content: yoast.twitter_description,
                },
                {
                  name: 'google-site-verification',
                  content: ''
                },
                {
                  name: `canonical`,
                  content: yoast.canonical,
                }
              ]
              .concat(metaImage ? [
                {
                  property: `og:image`,
                  content: metaImage
                },
                {
                  property: `og:image:alt`,
                  content: title,
                },
                {
                  property: 'og:image:width',
                  content: image.width
                },
                {
                  property: 'og:image:height',
                  content: image.height
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                }
              ] : [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
              ])
            }
          >
            <html lang="en" />
            
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  pathname: ``
}

SEO.propTypes = {
  yoast: PropTypes.object
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`