import React, { useEffect, useState, useRef } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import TransitionLink from "gatsby-plugin-transition-link";

// import Nav from "./Nav";
import "../sass/mobile_nav.scss";
import "../sass/nav.scss";
import { createPortal } from "react-dom";

function ReactPortal({ children }) {
  if (typeof window !== `undefined`) {
    return createPortal(children, document.body);
  } else {
    return null;
  }
}

function MobileNavOverlay({ menu, show, setShow }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (show) {
      // setTimeout needed, or focus does not set, maybe race condition
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        inputRef?.current?.querySelector?.("a").focus();
      }, 200);
    }
  }, [show]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        // Do something when the Esc key is pressed
        console.log('Esc key pressed');
        setShow(false)
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShow]);

  return (
    <div className="tank-nav">
      <div className={`tank-menu ${show ? "show" : ""}`} id="tank-menu">
        <div className="">
          <ul
            className="tank-menu__list l-content l-content--fixed"
            ref={inputRef}
          >
            {menu.items.map((item, i) => (
              <li key={i} className="tank-menu__item">
                <TransitionLink
                  to={`/${item.object_slug}`}
                  className="tank-menu__link t-h3"
                  onClick={() => setShow(show => !show)}
                >
                  {item.title}
                </TransitionLink>
                {item.description ? (
                  <p className="tank-menu__item-desc">{item.description}</p>
                ) : null}
                {item.wordpress_children ? (
                  <ul className="tank-menu__sub-menu">
                    {item.wordpress_children.map((child, iChild) => (
                      <li key={iChild} className="tank-menu__sub-menu__item">
                        <TransitionLink
                          to={`/${child.object_slug}`}
                          className="tank-menu__link t-h3"
                          onClick={() => setShow(show => !show)}
                        >
                          {child.title}
                        </TransitionLink>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

const MobileNav = ({ menu }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="mobile-only mobile-header">
      <header className="tank-header">
        <AniLink
          swipe
          direction="left"
          duration={0.4}
          className="tank-header__logo tank-header__logo-text"
          to="/"
        >
          Tank
        </AniLink>
        <div className="nav-menu-container">
          <TransitionLink
            to="/contact"
            className="tank-nav__menu-contact-link text-decoration-none"
          >
            <span className={`tank-nav__menu-contact-text`}>Contact</span>
          </TransitionLink>
          <button
            htmlFor="tank-menu-toggle"
            className={`tank-nav__menu-toggle-link ${show ? "show" : ""}`}
            onClick={() => {
              setShow(prev => !prev);
            }}
          >
            <span className={`tank-nav__menu-toggle-text`}>
              <span style={{ opacity: show ? "0" : "1" }}>Menu</span>
            </span>
          </button>
        </div>
      </header>
      <ReactPortal>
        <MobileNavOverlay menu={menu} show={show} setShow={setShow} />
      </ReactPortal>
    </div>
  );
};

export default MobileNav;
