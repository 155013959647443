import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import TransitionLink from "gatsby-plugin-transition-link";

import Header from "./Header";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./../sass/main.scss";
import "./../sass/layout.scss";

const Layout = ({ title = "", children }) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined" && window.innerWidth > 500) {
        if (window.scrollY > lastScrollY) {
          // if scroll down hide the navbar
          setShow(false);
        } else {
          // if scroll up show the navbar
          setShow(true);
        }

        // https://stackoverflow.com/questions/75164632/window-pageyoffset-getting-wrong-values-on-ipad
        // remember current page location to use in the next move
        // but do not allow negative value, because it will cause a bug on iphone bounce when scrolling back to top
        const onlyPositiveY = window.scrollY > 0 ? window.scrollY : 0; // prevent negative value
        setLastScrollY(onlyPositiveY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div className="tank">
      {/* <TransitionLink className={`tank__logo ${!show ? "hidden" : ""}`} to="/">
        <img
          className="desktop-logo"
          src={tankDesktopLogo}
          alt="Tank Desktop Header Logo"
        />
      </TransitionLink> */}
      <div id="site-content" className="tank__content">
        {/* <OldHeader /> */}
        <Header 
        // className={!show ? "hidden" : ""}
        isHidden={!show}
        />
        <main id="site-main" className="site-main" role="main">
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
