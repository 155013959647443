import React from "react";

import tankFooterLogo from "../../images/tankFooterLogo.svg";
import DividerLine from "../../components/blocks/DividerLine";
import "./../sass/footer.scss";

import AniLink from "gatsby-plugin-transition-link/AniLink";
import TransitionLink from "gatsby-plugin-transition-link";
const Footer = () => {
  return (
    <footer className="tank-footer">
      <DividerLine style="full_width" />
      <div className="tank-footer__content l-content">
        <div className="tank-footer__row">
          <div className="tank-footer__col">
            <img
              className="tank-footer__logo"
              src={tankFooterLogo}
              alt="Tank Logo"
            />
          </div>
        </div>
        <div className="tank-footer__row">
          <div className="tank-footer__col">
            <div>
              14 Tyler Street
              <br />
              Somerville, MA 02143
              <br />
              <div className="footer-link">
                <a href="tel:+16179954000" style={{
                  borderBottom: 'none'
                }}>617-995-4000</a>
              </div>
              <div class="footer-link">
                <a href="mailto:hello@tankdesign.com">hello@tankdesign.com</a>
              </div>
            </div>
          </div>
          <div className="tank-footer__col">
            <h6 className="tank-footer__col-title t-h6">Meet Tank</h6>
            <div className="footer-link">
              <TransitionLink
                to="/work/"
                top="entry"
                direction="up"
              >
                Work
              </TransitionLink>
            </div>
            <div className="footer-link">
              <TransitionLink
                to="/services/"
                top="entry"
                direction="up"
              >
                Services
              </TransitionLink>

            </div>
            <div className="footer-link">

              <AniLink
                swipe
                direction="right"
                top="entry"
                duration={0.4}
                to="/about/"
              >
                About
              </AniLink>
            </div>
            <div className="footer-link">

              <AniLink
                swipe
                direction="left"
                top="entry"
                duration={0.4}
                to="/careers/"
              >
                Careers
              </AniLink>
            </div>
          </div>
          <div className="tank-footer__col">
            <h6 className="tank-footer__col-title t-h6">Connect</h6>
            <div className="footer-link">

              <AniLink
                swipe
                direction="left"
                top="entry"
                duration={0.4}
                to="/contact/"
              >
                Contact us
              </AniLink>
            </div>
            <div className="footer-link">

              <TransitionLink
                to="/whats-new/"
                top="entry"
                direction="up"
              >
                Read our blog
              </TransitionLink>
            </div>
          </div>
          <div className="tank-footer__col">
            <h6 className="tank-footer__col-title t-h6">Follow us</h6>
            <div className="footer-link">
              <a href="https://www.facebook.com/tankdesign" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </div>
            <div className="footer-link">
              <a href="https://www.instagram.com/tank_design/" target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </div>
            <div className="footer-link">
              <a href="https://www.linkedin.com/company/tank-design" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </div>
          </div>
        </div>
        <p className="tank-footer__copyright">
          &copy;{" "}
          <span
            id="tankYear"
            dangerouslySetInnerHTML={{ __html: new Date().getFullYear() }}
          ></span>{" "}
          Tank Design. All rights reserved.{" "}

          <TransitionLink
            to="/legal-information/"
            top="entry"
            direction="up"
          >
            Legal Info
          </TransitionLink>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
