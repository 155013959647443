import React from "react";

const DividerLine = ({ style = "" }) => {
  if (style === "full_width") {
    return (
      <div className="divider-line l-content l-vspace">
        <hr />
      </div>
    );
  } else {
    return (
      <section className="divider-line divider-line--half_width l-content l-content--fixed l-col-container">
        <div className="l-column--left"></div>
        <div className="l-column--right">
          <hr />
        </div>
      </section>
    );
  }
};

export default DividerLine;
